import React from 'react';

const Spinner = () => (
  <div className='lds-spinner-wrapper'>
    <div className='lds-spinner'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Spinner;
