import React from 'react';
import axios from 'axios';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import trim from 'lodash/trim';
import Recaptcha from 'react-google-recaptcha';
import { withNamespaces } from 'react-i18next';
import Link from './Link';
import s from './LoginForm.module.scss';
import GlobalError from './GlobalError';
import Api from '../utils/api';
import Spinner from './Spinner';
import Cell from './Cell';
import Grid from './Grid';
import FiledError from './FieldError';
import InputFiled from './InputFiled';
import BackendErrors from '../utils/backendErrors';
import PageHeaderTitle from './PageHeaderTitle';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.api = new Api({
      gatsbyUrl: props.gatsbyUrl,
      pythonUrl: props.pythonUrl,
      i18n: props.i18n,
    });
    this.backendErrors = new BackendErrors(this.api, props.i18n);

    this.state = {
      usernameOrEmail: {
        value: '',
        valid: false,
        dirty: false,
        showError: false,
        errorMsg: [props.i18n.t('page_login:enter_email_or_username')],
      },
      password: {
        value: '',
        valid: false,
        dirty: false,
        errorMsg: [props.i18n.t('page_login:enter_password')],
        showError: false,
        showPassword: false,
      },
      isFormSubmitted: false,
      errorMsg: '',
      showForm: false,
      token: '',
      failedLoginsCount: 0,
      isMultipleLoginFail: false,
      isLoading: false,
      firstVisit: true,
    };

    this.onLoginInput = this.onLoginInput.bind(this);
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
    this.onShowPasswordMouseDown = this.onShowPasswordMouseDown.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onCaptchaExpired = this.onCaptchaExpired.bind(this);
    this.onLoginBtnClick = this.onLoginBtnClick.bind(this);
  }

  onLoginBtnClick() {
    this.setState(state => ({ ...state, ...{ isLoading: true } }));

    setTimeout(() => {
      const { password, usernameOrEmail } = this.state;
      if (!password.valid || !usernameOrEmail.valid) {
        this.setState(state => ({ ...state, ...{ isLoading: false } }));
      }
    }, 100);
  }

  onCaptchaExpired() {
    this.recaptcha.reset();
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.checkUserLogin();
    }
  }

  checkUserLogin() {
    const config = this.api.statusConfig();

    axios(config).then(response => {
      const isAuthenticated = get(response, 'data.authenticated');
      const failedLoginsCount = get(response, 'data.failed_logins_count');
      const isMultipleLoginFail = failedLoginsCount > 1;

      this.setState(state => merge(state, {
        isAuthenticated,
        failedLoginsCount,
        isMultipleLoginFail,
      }));

      if (isAuthenticated) {
        this.userAuthenticated();
      } else {
        this.userNotAuthenticated();
      }
    }).catch(e => {
      console.log(e);
    });
  }

  componentWillUnmount() {
    this.setState({ firstVisit: true });
  }

  getResetMessage() {
    const { i18n } = this.props;
    const { firstVisit } = this.state;
    const link = this.api.forgotPasswordUrlAbs();

    if (firstVisit) {
      this.setState({ firstVisit: false });
      return;
    }

    return { error: [i18n.t('page_login:reset_password_message', { link })] };
  }

  userAuthenticated() {
    window.location.href = this.api.accountUrl();
  }

  userNotAuthenticated() {
    const { isMultipleLoginFail } = this.state;

    this.setState(state => merge(state, {
      showForm: true,
      errorMsg: isMultipleLoginFail ? this.getResetMessage() : '',
    }));
  }

  validateForm() {
    const { usernameOrEmail, password, failedLoginsCount } = this.state;
    const isLoginValid = usernameOrEmail.value.length > 0;
    const isPasswordValid = password.value.length > 0;

    const fieldsState = {
      usernameOrEmail: {
        showError: !isLoginValid,
        valid: isLoginValid,
        dirty: true,
      },
      password: {
        showError: !isPasswordValid,
        valid: isPasswordValid,
        dirty: true,
      },
      isLoading: false,
    };

    if (!isPasswordValid || !isLoginValid || failedLoginsCount > 0) {
      this.setState(state => merge(state, fieldsState));
    }
  }

  get showRecaptcha() {
    const { failedLoginsCount } = this.state;
    const { recaptchaEnabled } = this.props;

    return recaptchaEnabled === 'true' && failedLoginsCount > 2;
  }

  submitForm(token) {
    const { i18n, pythonUrl } = this.props;
    const { usernameOrEmail, password } = this.state;

    const form = new FormData();

    if (this.showRecaptcha) {
      this.recaptcha.reset();
    }

    form.append('username_or_email', usernameOrEmail.value);
    form.append('password', password.value);

    if (token) {
      form.append('recaptcha_token', token);
    }

    this.setState({ isFormSubmitted: true });

    axios(this.api.loginConfig({ data: form })).then(res => {
      this.setState(state => ({ ...state, ...{ isLoading: false, errorMsg: '' } }));
      if (res.data.status === 'ok') {
        const next = getLocationParameter('next');
        if (next !== null) {
          window.location.href = next;
        } else {
          window.location.href = `${pythonUrl}${res.data.redirect_to}`;
        }
      }
    }).catch(error => {
      if (this.showRecaptcha) {
        this.recaptcha.reset();
      }

      if (!error.response) {
        this.setState({ isLoading: false, errorMsg: { general: [i18n.t('core:general_error')] } });
        this.validateForm();

        return;
      }

      const { errors, failed_logins_count } = error.response.data;
      let errorState;

      const self = this;
      if (errors.field_errors) {
        // show local filed errors
        const reduceFn = (acc, key) => merge(acc, {
          errorMsg: { general: self.backendErrors.array2messages(errors.general) },
          [camelCase(key)]: {
            errorMsg: self.backendErrors.array2messages(errors.field_errors[key]),
            valid: false,
            showError: true,
          },
        });

        errorState = reduce(Object.keys(errors.field_errors), reduceFn, {});
      }

      const multipleLoginFail = failed_logins_count > 1;
      const multipleLoginFailErrorMsg = this.getResetMessage();

      errorState = multipleLoginFail
        ? merge(errorState, { errorMsg: multipleLoginFailErrorMsg })
        : merge(errorState, { errorMsg: errors });

      this.setState(state => {
        const newState = merge(
          state,
          errorState,
          { isLoading: false },
          { failedLoginsCount: failed_logins_count },
        );

        return newState;
      });
    });
  }

  onSubmitForm(e) {
    e.preventDefault();

    const { usernameOrEmail } = this.state;

    this.validateForm();

    if (usernameOrEmail.value.length === 0) {
      return;
    }

    if (this.showRecaptcha) {
      this.recaptcha.execute();
    } else {
      this.submitForm();
    }
  }

  onShowPasswordClick() {
    const { password } = this.state;

    this.setState(state => merge(state, {
      password: { showPassword: !password.showPassword },
    }));
  }

  onShowPasswordMouseDown(e) {
    e.preventDefault();
  }

  onLoginInput(e) {
    const { i18n } = this.props;
    let { value } = e.target;
    let valid;
    let dirty;
    let showError;
    let errorMsg;

    value = trim(value);
    dirty = true;

    if (value.length === 0) {
      valid = false;
      showError = true;
      errorMsg = [i18n.t('page_login:enter_email_or_username')];
    } else {
      valid = true;
      showError = false;
    }

    this.setState(state => merge(state, {
      usernameOrEmail: {
        value,
        valid,
        dirty,
        showError,
        errorMsg,
      },
    }));
  }

  onPasswordInput(e) {
    const { i18n } = this.props;
    let { value } = e.target;
    let valid;
    let dirty;
    let showError;
    let errorMsg;

    value = trim(value);
    dirty = true;

    if (value.length === 0) {
      valid = false;
      showError = true;
      errorMsg = [i18n.t('page_login:empty_password')];
    } else {
      valid = true;
      showError = false;
    }

    this.setState(state => merge(state, { password: { value, valid, dirty, showError, errorMsg } }));
  }

  render() {
    const { isLoading, usernameOrEmail, password, errorMsg, isMultipleLoginFail, isFormSubmitted } = this.state;
    const { i18n, sitekey, brand, t } = this.props;

    return (
      <Grid>
        <PageHeaderTitle brandName={brand.name} title={t('page_login:sign_in')} />
        <Cell />
        <Cell style={{ padding: '0 24px' }} desktop={12} tablet={8} phone={4} align='middle'>
          <form onSubmit={this.onSubmitForm} data-test='l-form'>
            <header className={s.header}>
              {i18n.t('page_login:sign_in')}
            </header>
            {isFormSubmitted || isMultipleLoginFail
              ? <GlobalError data-test='l-global-err' errors={errorMsg} />
              : <div />
            }
            <div>
              <div className={s.field}>
                <label className={s.label} htmlFor='login'>
                  {i18n.t('page_login:username_or_email')}
                </label>
                <InputFiled
                  type='text'
                  name='login'
                  id='login'
                  hasError={usernameOrEmail.showError}
                  onInput={this.onLoginInput}
                  cref={input => { usernameOrEmail.input = input; }}
                  data-test='l-username-input'
                />
                {usernameOrEmail.showError && <FiledError data-test='l-filed-err-username' errorMsg={usernameOrEmail.errorMsg} />}
              </div>
              <div className={s.field}>
                <div className={s.field}>
                  <div className={s.passwordLabels}>
                    <label className={s.label} htmlFor='password'>
                      {i18n.t('page_login:password')}
                    </label>
                    <Link data-test='l-forgot-password' className={s.forgot} to={this.api.forgotPasswordUrl()}>
                      {i18n.t('page_login:forgot_password')}
                    </Link>
                  </div>
                  <div className={s.toggleWrapper}>
                    <InputFiled
                      type='password'
                      name='password'
                      id='password'
                      hasError={password.showError}
                      onInput={this.onPasswordInput}
                      cref={input => { password.input = input; }}
                      data-test='l-password-input'
                    />
                  </div>
                  {password.showError && <FiledError data-test='l-filed-err-password' errorMsg={password.errorMsg} />}
                </div>
              </div>
            </div>
            {isLoading ? (
              <button data-test='l-submit' onClick={e => e.preventDefault()} className={`${s.loginBtn}`}>
                <Spinner />
              </button>
            ) : (
              <button data-test='l-submit' onClick={this.onLoginBtnClick} className={s.loginBtn} type='submit' color='primary'>
                {i18n.t('page_login:sign_in')}
              </button>
            )}
            <hr className={s.hr} />
            <div className={s.noAccount}>
              {i18n.t('page_login:is_no_account')}
            </div>
            <Link
              to={this.api.orderUrl()}
              className={s.signUpBtn}
              data-test='link-to-order-url'
            >
              {i18n.t('page_login:sign_up')}
            </Link>
            {this.showRecaptcha
              ? (
                <Recaptcha
                  sitekey={sitekey}
                  ref={e => this.recaptcha = e}
                  size='invisible'
                  onChange={this.submitForm}
                  onExpired={this.onCaptchaExpired}
                />
              )
              : <div />
            }
          </form>
        </Cell>
        <Cell />
      </Grid>
    );
  }
}

function getLocationParameter(paramName) {
  const url = new URL(window.location.href);
  return url.searchParams.get(paramName);
}

export default withNamespaces()(LoginForm);
