import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layouts/index';
import LoginPageBody from '../components/LoginPageBody';
import LoginForm from '../components/LoginForm';

class LoginPage extends React.Component {
  render() {
    const {
      gatsbyUrl,
      pythonUrl,
      brand,
      recaptchaEnabled,
      invisibleRecaptchaSiteKey,
      affiliateUrl
    } = get(this.props, 'data.site.siteMetadata');
    const { history, location } = this.props;

    return (
      <Layout location={location}>
        <LoginPageBody brand={brand}>
          <LoginForm
            pythonUrl={pythonUrl}
            gatsbyUrl={gatsbyUrl}
            location={location}
            history={history}
            sitekey={invisibleRecaptchaSiteKey}
            recaptchaEnabled={recaptchaEnabled}
            brand={brand}
            affiliateUrl={affiliateUrl}
          />
        </LoginPageBody>
      </Layout>
    );
  }
}

const pageQuery = graphql`
  query LoginPageQuery {
    site {
      siteMetadata {
        gatsbyUrl
        pythonUrl
        invisibleRecaptchaSiteKey
        recaptchaEnabled
        affiliateUrl
        brand {
          name
          nameCom
          twitter
          facebook
        }
      }
    }
  }
`;

export default ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={data => <LoginPage data={data} location={location} />}
  />
);
